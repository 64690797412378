


































import Vue from 'vue';

export default Vue.extend({
    props: {
        slideIndex: {
            type: Number,
            default: 1,
        }
    },
    data: () => ({}),
});
