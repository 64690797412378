<template>
    <ContentCard theme="navy" icon="assets/images/illu-frog.svg">
        <template #title>SEE THE FROGS IN THE SCRUB</template>
        <template #description>
            Discover the amazing world of amphibians in Sydney's West!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'NCbTdnuk4fM')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Tongue-Twister.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
