


































import Vue from 'vue';
import FirstSection from "@/components/sections/FirstSection.vue";
import LastSection from "@/components/sections/LastSection.vue";
import FixedElements from "@/components/FixedElements.vue";
import ContentLighbox from "@/components/ContentLighbox.vue";
import SecondSection from "@/components/sections/SecondSection.vue";
import ThirdSection from "@/components/sections/ThirdSection.vue";
import FourthSection from "@/components/sections/FourthSection.vue";
import FifthSection from "@/components/sections/FifthSection.vue";
import SixthSection from "@/components/sections/SixthSection.vue";
import SeventhSection from "@/components/sections/SeventhSection.vue";
import EighthSection from "@/components/sections/EighthSection.vue";
import NinthSection from "@/components/sections/NinthSection.vue";
import TenthSection from "@/components/sections/TenthSection.vue";

export default Vue.extend<any, any, any, any>({
    name: 'App',
    components: {
        TenthSection,
        NinthSection,
        EighthSection,
        SeventhSection,
        SixthSection,
        FifthSection,
        FourthSection,
        ThirdSection,
        SecondSection,
        ContentLighbox, FixedElements, FirstSection, LastSection
    },
    data() {
        return {
            slideIndex: 0,
            options: {
                licenseKey: '8FB6632F-E3D2421D-8604FDFA-C2BA744D',
                anchors: ['intro', 'swamp', 'bush', 'water', 'nature', 'dinosaurs', 'how-to', 'plants', 'pre-scrub', 'scrub', 'bye'],
                onLeave: this.onSlideLeave,
            },
        };
    },
    mounted() {
        if (typeof window !== 'undefined') {
            if (window.localStorage.getItem('acknowledgement') !== 'yes') {
                this.$store.commit('SET_LIGHTBOX', 'We acknowledge Aboriginal and Torres Strait Islander peoples as the First Australians and Traditional Custodians of the lands where we live, learn and work.');
                window.localStorage.setItem('acknowledgement', 'yes');
            }
        }
    },
    methods: {
        onSlideLeave(origin: any, destination: any, direction: any) {
            this.slideIndex = destination.index;
        },
        jumpOnMobile() {
            if (typeof window !== 'undefined') {
                if (window.innerWidth < 768) {
                    this.$refs.fullpage.api.moveSectionDown();
                }
            }
        }
    }
});
