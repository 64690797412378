<template>
    <ContentCard icon="assets/images/illu-tulip.svg">
        <template #title>Snugglepot and Cuddlepie - or how seeds travel!</template>
        <template #description>Our little gumnut babies teach you how they travel to make
            sure there
            are trees everywhere!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'y4JjDQ6e5cU')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Soaring-Seeds.pdf" target="_blank" icon="file"
                        class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
