<template>
    <ContentCard theme="darkgreen" icon="assets/images/illu-rocks.svg">
        <template #title>ROCK SOLID SYDNEY - What<br> are you standing on?</template>
        <template #description>Have you ever wondered what's underneath you? Discover the world of
            rocks and stones of the Sydney area.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', '3KaPqf--XrQ')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Rock-Stars.pdf" target="_blank" icon="file" class="card__btn"
                        orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
