<template>
    <ContentCard theme="brown" icon="assets/images/illu-dingo.svg">
        <template #title>STRETCH LIKE A DINGO DOING YOGA!</template>
        <template #description>
            Had enough of sitting in front of the computer? Follow the little dingo cub when it
            stretches its limbs!
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'q5sAB4kukD8')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Goodnight-Dingo-Cub-with-Stretches.pdf" target="_blank"
                        icon="file" class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
