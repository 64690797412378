<template>
    <ContentCard icon="assets/images/illu-swamp.svg">
        <template #title>Explore Sydney's Swamp!</template>
        <template #description>Venture into the depth of Centennial Park's swamp and meet
            all its
            inhabitants!
        </template>
        <template #buttons>
            <ButtonPill href="https://app.situ360.com/e35b0f/7313/42786/public"
                        target="_blank"
                        icon="play" class="card__btn me-8">VIEW 360 TOUR
            </ButtonPill>
            <ButtonPill href="/pdfs/Swamp-Scientists.pdf" target="_blank" icon="file"
                        class="card__btn" orange>ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
