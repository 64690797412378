import Vue from 'vue'
import Vuex from 'vuex';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    lightbox: '',
  },
  mutations: {
    SET_LIGHTBOX: (state, video: string) => state.lightbox = video,
    CLOSE_LIGHTBOX: (state) => state.lightbox = '',
  },
  actions: {
  },
  modules: {
  }
})
