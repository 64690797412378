







































import Vue from 'vue';
import CardDna from "../cards/CardDna.vue";
import CardRockSolid from "../cards/CardRockSolid.vue";
import CardDinosaur from "../cards/CardDinosaur.vue";


export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardDna,
        CardRockSolid,
        CardDinosaur,
    }
});
