































import Vue from 'vue';
import CardBringBackBush from "../cards/CardBringBackBush.vue";
import CardDingo from "../cards/CardDingo.vue";

export default Vue.extend<any, any, any, any>({
    props: {
        index: {
            type: Number,
            required: true,
        }
    },
    components: {
        CardBringBackBush,
        CardDingo,
    }
});
