<template>
    <ContentCard theme="darkgreen" icon="assets/images/illu-dna.svg">
        <template #title>How to extract DNA?<br> (from a strawberry)</template>
        <template #description>We are all made of DNA - find out how DNA research can help us to
            cure diseases.
        </template>
        <template #buttons>
            <ButtonPill icon="play" class="card__btn me-8"
                        @click.native="$store.commit('SET_LIGHTBOX', 'jFVBOMvo82U')">Watch Now
            </ButtonPill>
            <ButtonPill href="/pdfs/Extraction-action.pdf" target="_blank" icon="file" class="card__btn" orange>
                ACTIVITY
            </ButtonPill>
        </template>
    </ContentCard>
</template>

<script>
import ContentCard from '@/components/ContentCard';
import ButtonPill from '@/components/ButtonPill';
export default {
    components: {ButtonPill, ContentCard}
};
</script>
